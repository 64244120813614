import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Button } from '@blueprintjs/core';

import { history } from '../routers/AppRouter';
import { useDispatch, useSelector } from 'react-redux';

import {
  isAuthSelector,
  isUserWrongCredentialsSelector,
  startLogin,
} from '../../redux/modules/user';

export default function Login() {
  const [state, setState] = useState({ username: '', password: '' });

  const dispatch = useDispatch();

  const isAuth = useSelector(isAuthSelector);
  const isWrongCredentials = useSelector(isUserWrongCredentialsSelector);

  useEffect(() => {
    console.log('isAuth', isAuth);
    if (isAuth) {
      history.push('/dashboard');
    }
  }, [isAuth]);

  function submitLogin(e: SyntheticEvent) {
    e.preventDefault();
    dispatch(startLogin(state.username, state.password));
  }

  function onUsernameChange(e: SyntheticEvent) {
    const target = e.target as HTMLInputElement;
    setState({ ...state, username: target.value });
  }

  function onPasswordChange(e: SyntheticEvent) {
    const target = e.target as HTMLInputElement;
    setState({ ...state, password: target.value });
  }

  return (
    <div className='login'>
      <h1>Arkhana Gestion</h1>

      <div className='login__form'>
        {/*<form noValidate onSubmit={submitLogin}>*/}

        <form onSubmit={submitLogin}>
          <input
            onChange={onUsernameChange}
            value={state.username}
            className='bp3-input login__element'
            type='text'
            placeholder='Username'
            data-lpignore='true'
            dir='auto'
          />
          <input
            onChange={onPasswordChange}
            value={state.password}
            className='bp3-input login__element'
            type='password'
            placeholder='Password'
            autoComplete='current-password'
            dir='auto'
          />
          <Button type='submit' className='login__element'>
            Login
          </Button>
        </form>
      </div>

      {isWrongCredentials ? (
        <div style={{ color: 'red' }}>Wrong username or password</div>
      ) : (
        <React.Fragment />
      )}
    </div>
  );
}
