import React, { createRef, SyntheticEvent } from 'react';
import { TransactionApi, UserApi } from '@libat/api';
import {
  currentUserSelector,
  isDarkModeSelector,
  logout,
  setDarkMode,
} from '../redux/modules/user';
import { useDispatch, useSelector } from 'react-redux';
import { history } from './routers/AppRouter';
import { ApiConfig } from '@libat/shared';
import { Link } from 'react-router-dom';
import 'moment/locale/fr';
import { Button, Icon, Navbar, Switch } from '@blueprintjs/core';
import { Alignment } from '@blueprintjs/core/lib/esm/common/alignment';
import { IconNames } from '@blueprintjs/icons';
import LogoBlack from '../assets/logo_full_black.png';
import LogoWhite from '../assets/logo_full_white.png';

interface HeaderProps {
  exportPdf?: () => void;
}

export function Header({ exportPdf }: HeaderProps) {
  const dispatch = useDispatch();
  const inputFile = createRef<HTMLInputElement>();
  const isDarkMode = useSelector(isDarkModeSelector);

  const user = useSelector(currentUserSelector);

  async function logoutUser(e: SyntheticEvent) {
    e.preventDefault();
    const userApi = new UserApi(ApiConfig);
    dispatch(logout());
    await userApi.logout();
    history.push('../');
  }

  function importCsv(e: SyntheticEvent) {
    if (inputFile.current) {
      inputFile.current.click();
    }
  }

  async function onFileUpload(e: SyntheticEvent) {
    if (inputFile.current) {
      const files = inputFile.current.files;
      if (files && files.length) {
        // console.log(inputFile.current.files);
        // console.log(inputFile.current.files[0]);
        const transactionApi = new TransactionApi(ApiConfig);

        try {
          await transactionApi.importTransactions({ uploadedFile: files[0] });
        } catch (e) {
          console.log(e);
        }
      }
    }
  }

  return (
    <Navbar>
      <Navbar.Group align={Alignment.LEFT}>
        <Navbar.Heading>
          <img
            src={
              isDarkMode ? LogoWhite : LogoBlack
            }
            alt='Logo LiBat'
            style={{ height: '25px', opacity: 0.75 }}
          />
        </Navbar.Heading>

        <Link
          style={{ textDecoration: 'none', color: 'rgb(245, 248, 250)' }}
          to={'/dashboard'}
        >
          <Button className='bp3-minimal' icon={IconNames.HOME}>
            Accueil
          </Button>
        </Link>

        {!user?.isVisitor ?? false ? (
          <Link
            style={{ textDecoration: 'none', color: 'rgb(245, 248, 250)' }}
            to={'/invoice'}
          >
            <Button className='bp3-minimal' icon={IconNames.SAVED}>
              Creer QR Code
            </Button>
          </Link>
        ) : (
          <React.Fragment />
        )}

        <input
          type='file'
          id='file'
          ref={inputFile}
          onChange={onFileUpload}
          hidden={true}
        />

        {!user?.isVisitor ?? false ? (
          <Button
            className='bp3-minimal'
            onClick={importCsv}
            icon={IconNames.ADD_TO_FOLDER}
          >
            Importer CSV
          </Button>
        ) : (
          <React.Fragment />
        )}

        {(!user?.isVisitor ?? false) && exportPdf ? (
          <Button
            className='bp3-minimal'
            onClick={exportPdf}
            icon={IconNames.SAVED}
          >
            Exporter PDF
          </Button>
        ) : (
          <React.Fragment />
        )}
      </Navbar.Group>

      <Navbar.Group align={Alignment.RIGHT}>
        <Icon
          icon={isDarkMode ? IconNames.MOON : IconNames.FLASH}
          style={{ marginRight: 5 }}
        />
        <Switch
          style={{ marginBottom: 0 }}
          checked={isDarkMode}
          onChange={(e: any) => dispatch(setDarkMode(!isDarkMode))}
          data-lpignore='true'
        />

        <Button
          className='bp3-minimal'
          icon={IconNames.LOG_OUT}
          onClick={logoutUser}
        >
          Logout
        </Button>
      </Navbar.Group>
    </Navbar>
  );
}
